import React, { Component } from "react";
import { connect } from "react-redux";
import Container from "@material-ui/core/Container";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Joi from "joi-browser";
import {
  getServiceRequestDetailsById,
  updateManualDelivery,
} from "../../actions/serviceRequestActions";
import { lightBlue } from "@material-ui/core/colors";
import { fetchEnvs } from "../../actions/authActions";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import InputAdornment from "@material-ui/core/InputAdornment";
import Avatar from "@material-ui/core/Avatar";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

const styles = (theme) => ({
  header: {
    height: "10%",
    margin: "0px 0px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "22px",
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF",
  },
  content: {
    height: "70%",
    margin: "10px 0px",
    overflow: "auto",
  },
  footer: {
    width: "100%",
    height: "20%",
    marginTop: "10px",
    // display: 'flex',
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
  },
  headGridTwo: {
    float: "right",
    marginRight: "2px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px",
  },
  modalContainer: {
    width: "45%",
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    padding: "0px 0px",
  },
  tableCellSmall: {
    fontSize: "12px",
    padding: "6px 0px 6px 16px",
  },
  head: {
    fontWeight: 700,
    fontSize: "12px",
  },
  lightBlue: {
    color: theme.palette.getContrastText(lightBlue[50]),
    backgroundColor: lightBlue[50],
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  roundedIcon: {
    marginRight: "10px",
    marginLeft: "10px",
  },
});
class ManualDeliveryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactNumber: "",
      partnerName: "",
      tracking_url: "",
      pickupOTP: "",
      dropOTP: "",
      deliveryStatus: "",
      onSubmitBesidesClear: false,
      getLastManualDeliveryInfo: null,
      data: {
        text: "",
      },
      partnerNameError: ""
    };
  }

  handleFilterChangeContactNumber = async (event) => {
 
    const { name, value } = event.target;

    // Prevent the state update if the contactNumber exceeds 10 digits
    if (name === "contactNumber") {
      // Only allow 10 digits
      if (value.length > 10) {
        return; // Do not allow typing more than 10 digits
      }
  
      // Check if the entered value contains only digits (valid number)
      if (!/^\d*$/.test(value)) {
        this.setState({
          contactNumberError: "Invalid contact number, only digits allowed.",
        });
        return;
      }
  
      // Reset error if the input is valid
      this.setState({ contactNumberError: "" });
    }
  
    // Update the state if the value passes validation
    this.setState({ [event.target.name]: event.target.value });


    console.log("this.state.contactNumber ------------>", this.state.contactNumber)
    
  };

  handleFilterChangePartnerName =  async (event) =>{
    this.setState({ [event.target.name]: event.target.value });
    if (!this.state.partnerName) {
      this.setState({ partnerNameError: "Required" });
    } 
    this.setState({ partnerNameError: "" });
  };
  handleFilterChange = async (event) => {
    this.setState({ [event.target.name]: event.target.value });

  }
  onSubmitBesidesClear = async () => {   
    this.setState({
      partnerName: "",
      contactNumber: "",
      tracking_url: "",
      pickupOTP: "",
      dropOTP: "",
      deliveryStatus: "",
      partnerNameError: "",
      contactNumberError: ""
    });
    //  this.setState({
    //     partnerName:
    //  })
  };
  onSubmit = async () => {
    let hasError = false;
    if(this.state.contactNumber &&
      (this.state.contactNumber.length !== 10 ||
        !/^\d{10}$/.test(this.state.contactNumber))){
     hasError = true
     this.setState({contactNumberError: "Invalid"}) 
    }
    if(!this.state.partnerName){
      hasError = true
      this.setState({partnerNameError: "Required"}) 
    }
      if(!hasError){
        let payload = {
          id: this.props.serviceId,
          deliveryInfo: {
            partnerName: this.state.partnerName ? this.state.partnerName : "",
            contactNumber: this.state.contactNumber ? this.state.contactNumber : "",
            tracking_url: this.state.tracking_url ? this.state.tracking_url : "",
            pickupOTP: this.state.pickupOTP ? this.state.pickupOTP : "",
            dropOTP: this.state.dropOTP ? this.state.dropOTP : "",
            deliveryStatus: this.state.deliveryStatus ? this.state.deliveryStatus : "runner_accepted",
            isManualDelivery: true,
          
          },
        };
        this.props.updateManualDelivery(payload);
        this.props.getServiceRequestDetailsById(this.props.serviceId);
        this.props.onClose();
        this.setState({ onSubmitBesidesClear: false, contactNumberError: "", partnerNameError: ""});  
      } 
     
    
  };

  componentDidMount() {

      this.setState({
        
        contactNumber: this.props?.getLastManualDeliveryInfoObj?.contact
          ? this.props?.getLastManualDeliveryInfoObj.contact
          : "",
        partnerName: this.props?.getLastManualDeliveryInfoObj?.runner?.name
          ? this.props?.getLastManualDeliveryInfoObj.runner?.name
          : "",
        tracking_url: this.props?.getLastManualDeliveryInfoObj?.trackingURL
          ? this.props?.getLastManualDeliveryInfoObj.trackingURL : this.props?.getLastManualDeliveryInfoObj?.tracking_url
          ? this.props?.getLastManualDeliveryInfoObj.tracking_url
          : "",
        pickupOTP: this.props?.getLastManualDeliveryInfoObj?.pickupOTP
          ? this.props?.getLastManualDeliveryInfoObj.pickupOTP
          : "",
        dropOTP: this.props?.getLastManualDeliveryInfoObj?.dropOTP
          ? this.props?.getLastManualDeliveryInfoObj.dropOTP
          : "",
        deliveryStatus: this.props?.getLastManualDeliveryInfoObj?.state
          ? this.props?.getLastManualDeliveryInfoObj.state
          : "",
      });
    
  }
  componentDidUpdate(prevProps) {
    if (prevProps.getLastManualDeliveryInfoObj !== this.props.getLastManualDeliveryInfoObj) {
      const info = this.props.getLastManualDeliveryInfoObj || {};
      this.setState({
        contactNumber: info.contact || "",
        partnerName: info.runner?.name || "",
        tracking_url: info.trackingURL || info.tracking_url || "",
        pickupOTP: info.pickupOTP || "",
        dropOTP: info.dropOTP || "",
        deliveryStatus: info.state || "",
      });
    }
  }
  render() {
    const { classes } = this.props;
    // const { getLastManualDeliveryInfo} = this.props?.getLastManualDeliveryInfo
  console.log("this.state --->", this.state.contactNumber.length)

    return (
      <Modal
        className={classes.modal}
        open={this.props.open}
        closeAfterTransition
      >
        <div style={{ height: "90%", width: "100%" }}>
          <Container className={classes.modalContainer}>
            <Grid container className={classes.header} spacing={1}>
              <Grid item xs={8}>
                <Typography variant="h5" gutterBottom>
                  Manual Delivery
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <CloseIcon
                  className={classes.headGridTwo}
                  onClick={() => {
                    this.props.onClose();  
                    this.setState({
                     partnerName: this.props?.getLastManualDeliveryInfoObj?.runner?.name
                     ? this.props?.getLastManualDeliveryInfoObj.runner?.name
                     : "",
                     contactNumber: this.props?.getLastManualDeliveryInfoObj?.contact
                     ? this.props?.getLastManualDeliveryInfoObj.contact
                     : "",
                     tracking_url: this.props?.getLastManualDeliveryInfoObj?.trackingURL
                     ? this.props?.getLastManualDeliveryInfoObj.trackingURL : this.props?.getLastManualDeliveryInfoObj?.tracking_url
                     ? this.props?.getLastManualDeliveryInfoObj.tracking_url
                     : "",
                     pickupOTP: this.props?.getLastManualDeliveryInfoObj?.pickupOTP
                     ? this.props?.getLastManualDeliveryInfoObj.pickupOTP
                     : "",
                     dropOTP: this.props?.getLastManualDeliveryInfoObj?.dropOTP
                     ? this.props?.getLastManualDeliveryInfoObj.dropOTP
                     : "",
                     deliveryStatus: this.props?.getLastManualDeliveryInfoObj?.state
                     ? this.props?.getLastManualDeliveryInfoObj.state
                     : "",
                     partnerNameError: "",
                     contactNumberError: ""
                    });

                  }}
                />
              </Grid>
            </Grid>

            <Container className={classes.content}>
              <Grid
                container
                className={classes.grid}
                spacing={1}
                justify="center"
                alignItems="center"
              >
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" gutterBottom>
                    Partner Name *
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" gutterBottom>
                    Contact Number *
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                className={classes.grid}
                spacing={1}
                justify="center"
                alignItems="center"
              >
                <Grid item xs={12} sm={6} classname>
                  <TextField
                    style={{ width: "90%" }}
                    margin="dense"
                    name="partnerName"
                    label="Partner Name"
                    variant="outlined"
                    onChange={this.handleFilterChangePartnerName}
                    value={this.state.partnerName}
                    error={this.state.partnerNameError}
                    // helperText={this.state.partnerNameError && this.state.partnerNameError !== "" ? this.state.partnerNameError : null}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton style={{ marginRight: "-24px" }}
                          disableRipple
                          disableFocusRipple
                          disableTouchRipple
                          disabled>
                            <Avatar
                              className={classes.lightBlue}
                              variant="square"
                            >
                              <EditIcon
                                color="action"
                                style={{ height: "20px" }}
                              />
                            </Avatar>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                   
                  />
                   {this.state.partnerNameError && this.state.partnerNameError !== "" ? (
                      <FormHelperText style={{ color: "red" }}>
                        {this.state.partnerNameError}
                      </FormHelperText>
                    ) : null
                    }
                </Grid>
                <Grid item xs={12} sm={6} classname>
                  <TextField
                    style={{ width: "90%" }}
                    margin="dense"
                    name="contactNumber"
                    label="Contact Number"
                    variant="outlined"
                    value={this.state.contactNumber}
                    onChange={this.handleFilterChangeContactNumber}
                    error={
                      this.state.contactNumber &&
                      (this.state.contactNumber.length !== 10 ||
                        !/^\d{10}$/.test(this.state.contactNumber)) // Validate for 10 digits
                    }
                    helperText={
                      this.state.contactNumber &&
                      (this.state.contactNumber.length !== 10 ||
                        !/^\d{10}$/.test(this.state.contactNumber))
                        ? "Invalid"
                        : null
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton style={{ marginRight: "-24px" }}
                          disableRipple
                          disableFocusRipple
                          disableTouchRipple
                          disabled>
                            <Avatar
                              className={classes.lightBlue}
                              variant="square"
                            >
                              <EditIcon
                                color="action"
                                style={{ height: "20px" }}
                              />
                            </Avatar>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                className={classes.grid}
                spacing={1}
                justify="center"
                alignItems="center"
              >
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" gutterBottom>
                    Tracking URL
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography variant="subtitle2" gutterBottom>
                    OTP(Pickup)
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography variant="subtitle2" gutterBottom>
                    OTP(Drop)
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                className={classes.grid}
                spacing={1}
                justify="center"
                alignItems="center"
              >
                <Grid item xs={12} sm={6} classname>
                  <TextField
                    style={{ width: "90%" }}
                    margin="dense"
                    name="tracking_url"
                    label="Tracking URL"
                    variant="outlined"
                    value={this.state.tracking_url}
                    onChange={this.handleFilterChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton style={{ marginRight: "-24px" }}
                          disableRipple
                          disableFocusRipple
                          disableTouchRipple
                          disabled>
                            <Avatar
                              className={classes.lightBlue}
                              variant="square"
                            >
                              <EditIcon
                                color="action"
                                style={{ height: "20px" }}
                              />
                            </Avatar>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} classname>
                  <TextField
                    style={{ width: "85%" }}
                    margin="dense"
                    name="pickupOTP"
                    //   label="Tracking URL"
                    variant="outlined"
                    onChange={this.handleFilterChange}
                    value={this.state.pickupOTP}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton style={{ marginRight: "-24px" }}
                          disableRipple
                          disableFocusRipple
                          disableTouchRipple
                          disabled>
                            <Avatar
                              className={classes.lightBlue}
                              variant="square"
                            >
                              <EditIcon
                                color="action"
                                style={{ height: "20px" }}
                              />
                            </Avatar>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} classname>
                  <TextField
                    style={{ width: "85%" }}
                    margin="dense"
                    name="dropOTP"
                    //   label="Tracking URL"
                    variant="outlined"
                    onChange={this.handleFilterChange}
                    value={this.state.dropOTP}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton style={{ marginRight: "-24px" }}
                          disableRipple
                          disableFocusRipple
                          disableTouchRipple
                          disabled>
                            <Avatar
                              className={classes.lightBlue}
                              variant="square"
                            >
                              <EditIcon
                                color="action"
                                style={{ height: "20px" }}
                              />
                            </Avatar>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                className={classes.grid}
                spacing={1}
                justify="center"
                alignItems="center"
              >
                <Grid item xs={12} sm={4} classname></Grid>
                <Grid item xs={12} sm={4} classname>
                  <Button
                    fullWidth
                    variant="contained"
                    margin="normal"
                    color="default"
                    onClick={this.onSubmitBesidesClear}
                    style={{ margin: "10px", padding: "5px 50px" }}
                  >
                    CLEAR
                  </Button>
                </Grid>               
              </Grid>

              <Grid
                container
                className={classes.grid}
                spacing={1}
                justify="center"
                alignItems="center"
                style={{ paddingTop: "30px" }}
              >
                <Grid item xs={12} sm={3} classname>
                  <Typography variant="subtitle2" gutterBottom>
                    Delivery Status
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={5} classname>
                  <FormControl fullWidth margin="dense" variant="outlined">
                    <InputLabel>Select Delivery Status</InputLabel>
                    <Select
                      labelId=""
                      name="deliveryStatus"
                      value={this.state.deliveryStatus}
                      onChange={this.handleFilterChange}
                      // MenuProps={this.MenuProps}
                      style={{ height: "40px", width: "100%" }}
                      label="Select Delivery Status"

                      // disabled={this.state.paymentMode !== "CashFree"}
                    >
                      <MenuItem
                        value={"runner_accepted"}
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        <ListItemText primary="IN_PROGRESS" />
                      </MenuItem>
                      
                      <MenuItem
                        value={"started_for_delivery"}
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        <ListItemText primary="OUT_FOR_DELIVERY" />
                      </MenuItem>
                      <MenuItem
                        value={"delivered"}
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        <ListItemText primary="DELIVERY_DONE" />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} classname></Grid>
              </Grid>
            </Container>

            <Container className={classes.footer}>
              <Grid
                container
                className={classes.grid}
                spacing={1}
                justify="center"
                alignItems="center"
              >
                <Grid item xs={12} sm={3} classname></Grid>
                <Grid item xs={12} sm={3} classname>
                  <Button
                   fullWidth
                    variant="contained"
                    margin="normal"
                    color="default"
                    onClick={() => {
                      this.props.onClose();  
                      this.setState({
                       partnerName: "",
                       contactNumber: "",
                       tracking_url: "",
                       pickupOTP: "",
                       dropOTP: "",
                       deliveryStatus: "",
                       partnerNameError: "",
                       contactNumberError: ""
                      });
  
                    }}
                    // style={{ margin: "10px", padding: "5px 50px" }}
                  >
                    CANCEL
                  </Button>
                </Grid>
                <Grid item xs={12} sm={3} classname>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    margin="normal"
                    // style={{ margin: "10px", padding: "5px 50px" }}
                    onClick={this.onSubmit}
                    color="primary"
                  >
                    SAVE
                  </Button>
                </Grid>
                <Grid item xs={12} sm={3} classname></Grid>
              </Grid>
            </Container>
          </Container>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => (

  {
  data: state,
  env: state.auth.env,
});
const mapDispatchToProps = (dispatch) => ({
  getServiceRequestDetailsById: (data, serviceId, history) =>
    dispatch(getServiceRequestDetailsById(data, serviceId, history)),
  fetchEnvs,
  updateManualDelivery: (data, serviceId, history) =>
    dispatch(updateManualDelivery(data, serviceId, history)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ManualDeliveryModal));
