import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { changeHeaderName } from "../../actions/dashboardActions";
import {
  addNewNotice,
  clearMsg,
  getAllNotices,
  updateNoticeFlag,
  exportNotices,
  updateNotice,
  deleteNotice
} from "../../actions/noticeAppAction";
import Modal from "@material-ui/core/Modal";
import AddNoticeForm from "./AddNoticeForm";
import { fetchEnvs } from "../../actions/authActions";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NoticeTable from "./NoticeTable";
import CloseIcon from "@material-ui/icons/Close";
import EditNoiceForm from "./EditNoiceForm";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
// import { Console } from "winston/lib/winston/transports";

const styles = (theme) => ({
  container: {
    width: "100%",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    backgroundColor: "#fff",
  },
  div1: {
    width: "screen",
    height: "80px",
    padding: "30px 0px 5px",
    borderBottom: "2px solid black",
    overflow: "auto",
  },
  headerName: {
    fontSize: "26px",
    fontFamily: "bold",
  },

  table: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  content: {
    flexGrow: 1,
    height: "100%",
    width: "120%",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  formControl: {
    minWidth: 225,
    maxWidth: 300,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  heading: {
    fontSize: "14px",
    fontWeight: 500,
    marginleft: "4px",
  },
  addModalContainer: {
    width: "30%",
    height: "50%",
    backgroundColor: theme.palette.background.paper,
    padding: "0px 0px",
  },
  modal: {
    display: "flex",
    overflow: "hidden",
    padding: "25px 0px 0px 0px ",
    float: "left",
  },
  modalToggle: {
    display: "flex",
    overflow: "hidden",
    padding: "25px 0px 0px 0px ",
    float: "left",
  },
  mainContainer2: {
    width: "50%",
    height: "40%",
    backgroundColor: theme.palette.background.paper,
    padding: "0px 0px",
  },
  headGridTwo: {
    float: "right",
    marginRight: "2px",
  },
  div5: {
    height: "25%",
    margin: "0px -24px 0px -24px",
    width: "auto!important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.primary.main,
  },
  footer: {
    height: '10%',
    margin: '20px 0px 10px 0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

  },



});

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
  },
})(Typography);

class InAppNotice extends Component {
  constructor(props) {
    super(props);
    this.state = JSON.parse(window.localStorage.getItem("inAppNotice")) || {
      modalOpen: false,
      snackOpen: false,
      sucessMsg: null,
      errorMsg: null,
      page: 0,
      rowsPerPage: 5,
      rows: [],
      showDownloadIcon: false,
      order: -1,
      noticeId: "",
      noticeName: "",
      role: "",
      noticeType: "",
      filterStartDate: "",
      filterEndDate: "",
      timeDateRange: "",
      toggleModalOpen: false,
      toggleIsActive: false,
      toggleRole: "",
      toggleId: "",
      toggleMaintenance: [],
      toggleNoticeName: "",
      handleEditModal: false,
      editRecordsData: "",
      deleteRecord: "",
      deleteOpen: false,



    };
  }

  componentDidMount() {
    this.props.changeHeaderName("In App Notice");
    this.props.fetchEnvs();
    window.addEventListener("beforeunload", () =>
      localStorage.removeItem("inAppNotice")
    );
    this.fetchNoticesRecords();
    this.setState({
      snackOpen: false,
    })
  }

  fetchNoticesRecords = async () => {
    let payload = {
      order: Number(this.state.order),
      skip: this.state.page * this.state.rowsPerPage,
      limit: this.state.rowsPerPage,
      filterStartDate: this.state.filterStartDate,
      filterEndDate: this.state.filterEndDate,
      noticeId: this.state.noticeId.trim(),
      noticeName: this.state.noticeName,
      role: this.state.role,
      noticeType: this.state.noticeType,
    };
    this.props.getAllNotices(payload);
    this.setState({ showDownloadIcon: false });
    localStorage.setItem("inAppNotice", JSON.stringify(this.state));
  };

  fetchExportToExcel = async () => {
    let payload = {
      order: Number(this.state.order),
      skip: this.state.page * this.state.rowsPerPage,
      limit: this.state.rowsPerPage,
      filterStartDate: this.state.filterStartDate,
      filterEndDate: this.state.filterEndDate,
      noticeId: this.state.noticeId.trim(),
      noticeName: this.state.noticeName,
      role: this.state.role,
      noticeType: this.state.noticeType,
    };
    this.props.exportNotices(payload);
    this.setState({ showDownloadIcon: true });
  };

  resetFilter = async () => {
    this.setState(
      {
        noticeId: "",
        noticeName: "",
        filterStartDate: "",
        role: "",
        noticeType: "",
        filterEndDate: "",
        timeDateRange: "",
        page: 0,
      },
      () => {
        this.fetchNoticesRecords();
      }
    );
  };

  handleModalOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleModalClose = () => {
    this.setState({ modalOpen  : false });
    this.setState({ deleteOpen : false });
  };
  handleToggleModalClose = () => {
    this.setState({ toggleModalOpen: false });
  };
  handleEditModalClose = () => {
    this.setState({ handleEditModal: false });
  };

  addNotice = async (payload) => {
    let payloadNotice = {
      noticeName: payload.noticeName,
      role: payload.role,
      noticeType: payload.noticeType,
      message: payload.message,
      notificationBody : payload.notificationBody,
      photo: {
        path: payload.path,
        title: payload.title,
      },
      option: payload.option ? payload.option : "",  
      businessIds: payload.businessIds ? payload.businessIds : "",  
      businessSpecificOption: payload.businessSpecificOption ? payload.businessSpecificOption : "",  
    };

    await this.props.addNewNotice(payloadNotice);
    this.fetchNoticesRecords();
    this.setState({ handleEditModal: false });

  };

  updateNotice = async (payload) => {
    let updateNotice = {
      _id: payload._id,
      noticeName: payload.noticeName,
      role: payload.role,
      message: payload.message,
      notificationBody : payload.notificationBody,
      photo: {
        path: payload.path,
        title: payload.title,
      },
      option: payload.option ? payload.option : "",  
      businessIds: payload.businessIds ? payload.businessIds : "",  
      businessSpecificOption: payload.businessSpecificOption ? payload.businessSpecificOption : "", 
    };
    await this.props.updateNotice(updateNotice);
    this.fetchNoticesRecords();
    this.setState({ handleEditModal: false });
  };

  handleSnackClose = () => {
    this.setState({ snackOpen: false });
    this.setState({ modalOpen: false });
    this.setState({ sucessMsg: null });
    this.setState({ errorMsg: null });
    this.setState({ editMode: false });

  };

  componentDidUpdate(prevProps, prevState) {
   
      if (this.props.notice.sucessMsg) {
        this.setState({ sucessMsg: this.props.notice.sucessMsg });
        this.setState({ snackOpen: true });
        this.setState({ modalOpen: false });
        this.setState({ deleteOpen: false });
        this.setState({ toggleModalOpen: false });
        this.setState({ handleEditModal: false });
        this.props.clearMsg();
      } else if (this.props.notice.errorMsg) {
        this.setState({ errorMsg: this.props.notice.errorMsg });
        this.setState({ snackOpen: true });
        this.props.clearMsg();
      }
  }
  enterPressed = (event) => {
    let code = event.keyCode;
    if (code === 13) {
      event.preventDefault();
    }
  };
  handleFilterChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleNoticeActiveRange = (event, picker) => {
    let orderRequestRange =
      picker.startDate.format("DD MMM YYYY") +
      " - " +
      picker.endDate.format("DD MMM YYYY");
    this.setState({
      filterStartDate: picker.startDate.format(),
      filterEndDate: picker.endDate.format(),
    });
    let chosenLabel =
      picker.chosenLabel !== "Custom Range"
        ? picker.chosenLabel
        : orderRequestRange;
    this.setState({ timeDateRange: chosenLabel });
  };

  setPage = (page) => {
    this.setState({ page }, () => this.fetchNoticesRecords());
  };

  setRowsPerPage = (rowsPerPage) => {
    this.setState({ rowsPerPage }, () => this.fetchNoticesRecords());
  };

  handleToggleSwitch = async (row) => {
     this.setState({
      toggleModalOpen: true,
      toggleIsActive: row.isActive,
      toggleRole: row.role,
      toggleId: row._id,
      toggleNoticeName: row.noticeName,
      toggleMaintenance: row.toggleMaintenance

    });
  }
  noticeMaintainenanceFlag = async () => {
    const payload = {
      _id: this.state.toggleId,
      role: this.state.toggleRole,
      isActive: !this.state.toggleIsActive,
      toggleMaintenance: this.state.toggleMaintenance,
    };
    await this.props.updateNoticeFlag(payload);
    this.fetchNoticesRecords();

  };
  handleNoticeEditOpen = async (data) => {
    this.setState({
      handleEditModal: true,
      editRecordsData: data
    });
  };
  handleDeleteOpen = async (value) => {
    this.setState({
      deleteOpen: true,
      deleteRecord: value
    });

  };

  onDelete = async () => {
    let payload = {
      _id: this.state.deleteRecord._id
    };
    await this.props.deleteNotice(payload);
    this.fetchNoticesRecords()
  }

  render = () => {
    const { classes } = this.props;
    var noticeData = this.props.notice.noticesList.data;
    var totalCount = this.props.notice.noticesList.totalCount;
    var activeShopper = this.props.notice.noticesList.activeShopper;
    var activeBusiness = this.props.notice.noticesList.activeBusiness;
    // console.log(`in the state of the snack`, this.state.handleSnackClose)

    let muiAlert = null;

    if (this.state.sucessMsg) {
      muiAlert = (
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={this.handleSnackClose}
          severity="success"
        >
          {this.state.sucessMsg}
        </MuiAlert>
      );
    } else if (this.state.errorMsg) {
      muiAlert = (
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={this.handleSnackClose}
          severity="error"
        >
          {this.state.errorMsg}
        </MuiAlert>
      );
    }
    return (
      <Container className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container
          className={classes.container}
          style={{ paddingBottom: "0px" }}
        >
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Search/Filter</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails onKeyDown={this.enterPressed}>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    margin="dense"
                    name="noticeId"
                    label="Notice Id / Name"
                    value={this.state.noticeId}
                    variant="outlined"
                    onChange={this.handleFilterChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth margin="dense" variant="outlined">
                    <InputLabel id="demo-simple-select-standard-label">
                      Role
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={this.state.role}
                      name="role"
                      onChange={this.handleFilterChange}
                      label="Role"
                    >
                      <MenuItem
                        value="Shopper"
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        Shopper
                      </MenuItem>
                      <MenuItem
                        value="Business"
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        Business
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth margin="dense" variant="outlined">
                    <InputLabel id="demo-simple-select-standard-label">
                      Notice Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={this.state.noticeType}
                      name="noticeType"
                      onChange={this.handleFilterChange}
                      label="Notice Type"
                    >
                      <MenuItem
                        value="Icon Notice"
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        Icon Notice
                      </MenuItem>
                      <MenuItem
                        value="Image Notice"
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        Image Notice
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <DateRangePicker
                    ranges={{
                      Today: [moment(), moment()],
                      Yesterday: [
                        moment().subtract(1, "days"),
                        moment().subtract(1, "days"),
                      ],
                      "Last 7 Days": [moment().subtract(6, "days"), moment()],
                      "Last 30 Days": [moment().subtract(29, "days"), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                      "Last Month": [
                        moment().subtract(1, "month").startOf("month"),
                        moment().subtract(1, "month").endOf("month"),
                      ],
                    }}
                    onApply={this.handleNoticeActiveRange}
                    maxSpan={{ months: 12 }}
                    maxDate={moment()}
                    showDropdowns
                  >
                    <TextField
                      fullWidth
                      margin="dense"
                      name="timeDateRange"
                      label="Active Date"
                      value={this.state.timeDateRange}
                      style={{ width: "235px" }}
                      variant="outlined"
                    />
                  </DateRangePicker>
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={8}></Grid>
                <Grid item xs={2}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    margin="normal"
                    onClick={this.resetFilter}
                  >
                    Reset
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    margin="normal"
                    color="primary"
                    onClick={() => this.setPage(0)}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Container>
        <Container
          style={{ width: "100%", height: "40px", paddingTop: "20px" }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{ float: "right" }}
            onClick={() => this.handleModalOpen()}
          >
            Add
          </Button>
        </Container>
        <Modal
          className={classes.modal}
          open={this.state.modalOpen}
          onClose={this.handleModalClose}
          closeAfterTransition
        >
          {
            <Container className={classes.addModalContainer}>
              <AddNoticeForm
                onSubmit={this.addNotice}
                onClose={this.handleModalClose}
                envs={this.props}
              />
            </Container>
          }
        </Modal>
        <Modal
          className={classes.modal}
          open={this.state.handleEditModal}
          onClose={this.handleEditModalClose}
          closeAfterTransition
        >
          {
            <Container className={classes.addModalContainer}>
              < EditNoiceForm
                onSubmitEdit={this.updateNotice}
                data={this.state.editRecordsData}
                onClose={this.handleEditModalClose}
                envs={this.props}
              />
            </Container>
          }
        </Modal>
        <Modal
          className={classes.modalToggle}
          open={this.state.toggleModalOpen}
          onClose={this.handleToggleModalClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Container className={classes.mainContainer2}>
            <Container>
              <Grid container className={classes.div5} spacing={2}>
                <Grid item xs={8}>
                  <WhiteTextTypography
                    variant="subtitle1"
                    color="inherit"
                    gutterBottom
                  >
                    {this.state.toggleIsActive === true
                      ? "INACTIVE NOTICE"
                      : "PUBLISH NOTICE"}
                  </WhiteTextTypography>
                </Grid>
                <Grid item xs={4}>
                  <WhiteTextTypography
                    variant="subtitle1"
                    color="inherit"
                    gutterBottom
                  >
                    <CloseIcon
                      className={classes.headGridTwo}
                      onClick={this.handleToggleModalClose}
                    />
                  </WhiteTextTypography>
                </Grid>
              </Grid>
            </Container>
            <Container style={{ marginTop: "1rem" }}>
              <Grid container className={classes.appBarSpacer}>
                {this.state.toggleIsActive === true
                  ? `Are you sure you want to  INACTIVE  the "${this.state.toggleNoticeName}"   to  "${this.state.toggleRole}" users ?`
                  : `Are you sure you want to  PUBLISH   the  "${this.state.toggleNoticeName}"  to  "${this.state.toggleRole}" users?`}
              </Grid>
            </Container>

            <Container className={classes.footer}>
              <Grid container spacing={2}>
                <Grid item xs={6}></Grid>
                <Grid item xs={3} className={classes.gridItem}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    margin="normal"
                    color={
                      "primary"
                    }
                    onClick={() =>
                      this.noticeMaintainenanceFlag(
                      )
                    }
                  >
                    {"YES"}
                  </Button>
                </Grid>
                <Grid item xs={3} className={classes.gridItem}>
                  <Button
                    fullWidth
                    variant="contained"
                    margin="normal"
                    color="default"
                    onClick={this.handleToggleModalClose}
                  >
                    NO
                  </Button>
                </Grid>

              </Grid>
            </Container>
          </Container>
        </Modal>

        <Dialog
          open={this.state.deleteOpen}
          onClose={this.handleModalClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete Notice</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete Notice ?<br></br>

            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleModalClose} color="primary">
              No
            </Button>
            <Button onClick={() => this.onDelete()} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>



        <Container className={classes.table}>
          <NoticeTable
            data={noticeData}
            totalCount={totalCount}
            activeBusiness={activeBusiness}
            activeShopper={activeShopper}
            exportToExcel={this.props.notice.exportToExcel}
            page={this.state.page}
            rowsPerPage={this.state.rowsPerPage}
            setPage={this.setPage}
            setRowsPerPage={this.setRowsPerPage}
            showDownloadIcon={this.state.showDownloadIcon}
            fetchExportToExcel={this.fetchExportToExcel}
            handleToggleSwitch={this.handleToggleSwitch}
            handleNoticeEditOpen={this.handleNoticeEditOpen}
            handleDeleteOpen={this.handleDeleteOpen}

          />
        </Container>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={this.state.snackOpen}
          autoHideDuration={1000}
          onClose={this.handleSnackClose}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
        >
          {muiAlert}
        </Snackbar>

        <Backdrop
          className={classes.backdrop}
          open={this.props.notice.isFetching}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  };
}
const mapStateToProps = (state) => {
  return {
    env: state.auth.env,
    notice: state.notice,
  };
};

export default connect(mapStateToProps, {
  changeHeaderName,
  fetchEnvs,
  addNewNotice,
  clearMsg,
  getAllNotices,
  exportNotices,
  updateNoticeFlag,
  updateNotice,
  deleteNotice
})(withStyles(styles)(InAppNotice));
