import React from 'react';
import { Field, reduxForm } from 'redux-form';
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
    form: {
        width: '100%',
        height: '100%',
    },
    header: {
        height: '15%',
        margin: '0px 0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
    },
    content: {
        height: '70%',
        margin: '10px 0px',
        overflow: 'auto',
    },
    footer: {
        height: '10%',
        margin: '0px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    headGridTwo: {
        float: 'right',
        marginRight : '2px'
    },
});

class AdminForm extends React.Component {


    renderTextField({ label, input, meta: { touched, invalid, error }, ...custom }) {
        return (
            <TextField
                label={label}
                placeholder={label}
                error={touched && invalid}
                helperText={touched && error}
                {...input}
                {...custom}
            />
        );
    }

    onSubmit = formValues => {
        this.props.onSubmit(formValues)
    }

    render() {
        const { classes } = this.props;
        return (
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} className={classes.form}>
                <Grid container className={classes.header} spacing={3}>
                    <Grid item xs={8}>
                        <Typography variant="h5" gutterBottom>
                            Add Admin
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <CloseIcon className={classes.headGridTwo} onClick={() => {this.props.onClose()}}/>
                    </Grid>
                </Grid>
                <Container className={classes.content}>
                    <div>
                        <Typography variant="body2" gutterBottom>
                                Please fill in this form to create an Admin account.
                        </Typography>
                    </div>
                    <div>
                        <Field name="username" component={this.renderTextField} label="User Name" fullWidth variant="outlined" margin="normal" />
                    </div>
                    <div>
                        <Field name="firstName" component={this.renderTextField} label="First Name" fullWidth variant="outlined" margin="normal" />
                    </div>
                    <div>
                        <Field name="lastName" component={this.renderTextField} label="Last Name" fullWidth variant="outlined" margin="normal" />
                    </div>
                    <div>
                        <Field name="email" component={this.renderTextField} label="Email" fullWidth variant="outlined" margin="normal" />
                    </div>
                    <div>
                        <Field name="mobileNumber" component={this.renderTextField} label="Contact Number" fullWidth variant="outlined" margin="normal" />
                    </div>
                    <div>
                        <Field name="password" component={this.renderTextField} label="Password" type="password" fullWidth variant="outlined" margin="normal" />
                    </div>
                    <div>
                        <Field name="repeatPassword" component={this.renderTextField} label="Confirm Password" type="password" fullWidth variant="outlined" margin="normal" />
                    </div>
                </Container>
                <Container className={classes.footer}>
                    <div>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            margin="normal"
                            color="primary">
                            Register
                            </Button>
                    </div>
                </Container>
            </form>
        );
    }
}

const validate = values => {
    const errors = {}

    const requiredFields = [
        'userName',
        'password',
        'repeatPassword',
        'firstName',
        'lastName',
        'email',
        'mobileNumber'
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })

    if (values.username) {
        if (values.username.length > 15) {
            errors.username = 'Must be 15 characters or less'
        }
        if (!/^([A-z])*[^\s]\1*$/i.test(values.username)) {
            errors.username = 'username should be alphanumeric and without space'
        }
    }

    if (values.firstName && !/^[a-z ]+$/i.test(values.firstName)) {
        errors.firstName = 'firstName should have only alphabets'
    }

    if (values.lastName && !/^[a-z ]+$/i.test(values.lastName)) {
        errors.lastName = 'lastName should have only alphabets'
    }

    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address'
    }

    if(values.password !== values.repeatPassword) {
        errors.repeatPassword = 'Password and Confirm Password has to be same'
    }

    if (values.mobileNumber && !/^\d{10}$/i.test(values.mobileNumber)) {
        errors.mobileNumber = 'Invalid mobile number'
    }

    return errors
}

export default reduxForm({
    form: 'AdminForm',
    validate
})(withStyles(styles)(AdminForm))
