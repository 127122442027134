import React from 'react';
import { Field, reduxForm } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import MUIRichTextEditor from "mui-rte";
import { stateToHTML } from "draft-js-export-html";
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import FormHelperText from "@material-ui/core/FormHelperText";
import { convertToRaw, ContentState, convertFromHTML } from 'draft-js'

const styles = theme => ({
    form: {
        width: '100%',
        height: '100%',
    },
    header: {
        height: '15%',
        margin: '0px 0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
    },
    content: {
        height: '70%',
        margin: '10px 0px',
        overflow: 'auto',
    },
    footer: {
        height: '10%',
        margin: '0px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    headGridTwo: {
        float: 'right',
        marginRight: '2px'
    },
    cursorPointer: {
        cursor: 'pointer'
    }
});

class FaqForm extends React.Component {
    constructor(props) {
        super();      
        const tempString= props?.editForm && props?.initialValues?.answer ? props.initialValues.answer : ""
        const contentHTML = convertFromHTML(tempString)
        const state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap)
        const contentString = JSON.stringify(convertToRaw(state));
        this.state = {
            editorState: "",
            answer: props.editForm && props.faq?.qanda?.answer ? props.faq.qanda.answer : "" ,
            richTextInitialValue:contentString,
            richTextError: false,
            selectedUserType: props.editForm ? props.faq.usertype : props.selectedTab
        };
    }

    onSubmit = formValues => {
        if (this.state.answer && this.state.selectedUserType) {
            formValues.answer = this.state.answer;
            formValues.userType = this.state.selectedUserType;
            if (this.props.editForm) {
                formValues.faqId = this.props.faq._id;
            }
            this.props.onSubmit(formValues);
        } else {
            if (!this.state.answer) {
                this.setState({ richTextError: true });
            }
        }
    }

    handleRichTextData = editorState => {
        if (editorState.getCurrentContent().getPlainText()) {
            this.setState({
                editorState,
                answer: stateToHTML(editorState.getCurrentContent()),
                richTextError: false
            })
        } else {
            this.setState({
                answer: "",
                richTextError: true
            })
        }
    };

    handleUserTypeChange = event => {
        this.setState({
            selectedUserType: event.target.value
        });
    };

    renderTextField({ label, input, meta: { touched, invalid, error }, ...custom }) {
        return (
            <TextField
                label={label}
                placeholder={label}
                error={touched && invalid}
                helperText={touched && error}
                {...input}
                {...custom}
            />
        );
    }

    render() {
        const { classes } = this.props;
        let modalFor = 'Add';

        if (this.props.editForm) {
            modalFor = 'Edit';
        }

        return (
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} className={classes.form}>
                <Grid container className={classes.header} spacing={3}>
                    <Grid item xs={8}>
                        <Typography variant="h5" gutterBottom>
                            {modalFor} FAQ
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <CloseIcon style={{ cursor: 'pointer' }} className={classes.headGridTwo} onClick={() => { this.props.onClose() }} />
                    </Grid>
                </Grid>
                <Container className={classes.content}>
                    <div>
                        <Field name="question" component={this.renderTextField} label="Question" fullWidth variant="outlined" margin="normal" />
                    </div>
                    <div>
                        <FormControl variant="outlined" fullWidth className={classes.formControl}>
                            <InputLabel htmlFor="outlined-age-native-simple">User Type</InputLabel>
                            <Select
                                native
                                value={this.state.selectedUserType}
                                onChange={this.handleUserTypeChange}
                                label="User Type"
                                inputProps={{
                                    name: 'usertype',
                                    id: 'outlined-age-native-simple',
                                }}
                            >
                                <option aria-label="None" value="" />
                                <option value="general">General</option>
                                <option value="consumer">Consumer</option>
                                <option value="serviceprovider">Business</option>
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                    <Container></Container>
                    <div>
                    {this.state.richTextError ? 
                    <FormHelperText style={{ color: "red" }}>
                        Answer Required
                    </FormHelperText>
                    : null
                    }
                    </div>
                        <MUIRichTextEditor
                            name="answer"
                            label="Answer"
                            toolbarButtonSize="small"
                            defaultValue={this.state.richTextInitialValue}
                            value={this.state.richTextInitialValue}
                            inlineToolbar={true}
                            error={this.state.richTextError}
                            onChange={this.handleRichTextData}
                        />
                    </div>
                </Container>
                <Container className={classes.footer}>
                    <div>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            margin="normal"
                            color="primary">
                            Save
                            </Button>
                    </div>
                </Container>
            </form>
        );
    }
}

const validate = values => {
    const errors = {}

    const requiredFields = [
        'question',
        'answer',
        'usertype'
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })

    return errors
}

export default reduxForm({
    form: 'FaqForm',
    validate
})(withStyles(styles)(FaqForm))
