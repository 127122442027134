import React, { Component } from "react";
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import TextField from "@material-ui/core/TextField";
import Divider from '@material-ui/core/Divider';
import { lightBlue } from '@material-ui/core/colors';
import FormHelperText from '@material-ui/core/FormHelperText';
import moment from 'moment';

const styles = theme => ({
	grid: {
		width: '100%',
		//display: 'flex',
		alignItems: 'center',
		// justifyContent: 'center',
		paddingLeft: "15px",
		paddingRight: "15px",
		margin: '0px 0px',

	},
	deleteBtn: {
		cursor: 'pointer',
		color: 'grey',
		//marginTop: "7px"
	},
	header: {
		height: '10%',
		margin: '0px 0px',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		paddingLeft: "22px",
		backgroundColor: theme.palette.primary.main,
	},
	headGridTwo: {
		float: 'right',
		marginRight: '2px'
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '0px'
	},
	modalContainer: {
		width: '100%',
		height: '100%',
		padding: '0px 0px',
		backgroundColor: theme.palette.background.paper,
		margin: '10px 0px',
		overflow: 'auto',
	},
	icon: {
		color: 'white',
	},
	mainContainer: {
		width: '50%',
		height: '80%',
		backgroundColor: theme.palette.background.paper,
		padding: '0px 0px',

	},
	lightBlue: {
		color: theme.palette.getContrastText(lightBlue[50]),
		backgroundColor: lightBlue[50],
		width: theme.spacing(4),
		height: theme.spacing(4),
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
});

const WhiteTextTypography = withStyles({
	root: {
		color: "#FFFFFF"
	}
})(Typography);

class SettlementAddForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
			status: "",
			vendorId: "",
			transactionId: "",
			settlementId: "",
			totalTransactionAmount: null,
			serviceCharge: null,
			serviceTax: null,
			settlementAmount: null,
			adjustedAmount: null,
			netSettled: null,
			transactionFrom: "",
			transactionFromTime: "",
			transactionTo: "",
			transactionToTime: "",
			settledOn: "",
			settledOnTime: "",
			utrNo: "",
			settlementIdError: false,
			transactionIdError: false,
			vendorIdError: false, 
			totalTransactionAmountError: false,
			serviceChargeError: false,
			serviceTaxError: false,
			settlementAmountError: false,
			adjustedAmountError: false,
			netSettledError: false,
			netSettledValidationError: false,
			transactionFromError: false,
			transactionFromTimeError: false,
			transactionToError: false,
			transactionToTimeError: false,
			settledOnError: false,
			settledOnTimeError: false,
			utrNoError: false,
			vendorIdCompareError: false,
			toError: false,
			settleError: false,
			saveBtn: false,
			settledOnCurrentError:false,
        }
    }
    componentDidMount() {
		if(this.props.businessData){
			// if(this.props.businessData.cashfreeVendorId)
			// 	this.setState({ vendorId: this.props.businessData.cashfreeVendorId });
			this.setState({ vendorId: this.props.businessData.businessId });
		}
		this.setState({ status: "SUCCESS" });
		if (this.props.edit) {
			this.setData();
		}
    };

	setData() {
		let settleDate = new Date(this.props.selectedRowEdit.settledOn);
		let settleHours = String(settleDate.getHours()).padStart(2, '0');
		// console.log("settleHours=======>",settleHours);
		let settleMin = String(settleDate.getMinutes()).padStart(2, '0');
		// console.log("settleMin=======>",settleMin);

		if(this.props.selectedRowEdit.transactionFrom){
			let fromDate = new Date(this.props.selectedRowEdit.transactionFrom);
			let fromHours = String(fromDate.getHours()).padStart(2, '0');
			// console.log("fromHours=======>",fromHours);
			let fromMin = String(fromDate.getMinutes()).padStart(2, '0');
			// console.log("fromMin=======>",fromMin);
			this.setState({
				transactionFrom: moment(this.props.selectedRowEdit.transactionFrom).format('YYYY-MM-DD'),
				// transactionFromTime: this.props.selectedRowEdit.transactionFromTime,
				transactionFromTime: fromHours + ':' + fromMin,
			})
		}
		if(this.props.selectedRowEdit.transactionTo){
			let toDate = new Date(this.props.selectedRowEdit.transactionTo);
			let toHours = String(toDate.getHours()).padStart(2, '0');
			// console.log("toHours=======>",toHours);
			let toMin = String(toDate.getMinutes()).padStart(2, '0');
			// console.log("toMin=======>",toMin);
			this.setState({
				transactionTo: moment(this.props.selectedRowEdit.transactionTo).format('YYYY-MM-DD'),
				// transactionToTime: this.props.selectedRowEdit.transactionToTime,
				transactionToTime: toHours + ':' + toMin,
			})
		}

		this.setState({
			settlementId: this.props.selectedRowEdit.settlementId,
			// vendorId: this.props.selectedRowEdit.vendorId,
			transactionId: this.props.selectedRowEdit.transactionId && this.props.selectedRowEdit.transactionId !== null && this.props.selectedRowEdit.transactionId !== undefined && this.props.selectedRowEdit.transactionId !== "" ? this.props.selectedRowEdit.transactionId : "",
			vendorId: this.props.selectedRowEdit.businessId,
			totalTransactionAmount: this.props.selectedRowEdit.totalTransactionAmount,
			serviceCharge: this.props.selectedRowEdit.serviceCharge,
			serviceTax: this.props.selectedRowEdit.serviceTax,
			settlementAmount: this.props.selectedRowEdit.settlementAmount,
			adjustedAmount: this.props.selectedRowEdit.adjustedAmount,
			netSettled: this.props.selectedRowEdit.netSettledAmount,
			settledOn: moment(this.props.selectedRowEdit.settledOn).format('YYYY-MM-DD'),
			// settledOnTime: this.props.selectedRowEdit.settledOnTime,
			settledOnTime: settleHours + ':' + settleMin,
			utrNo: this.props.selectedRowEdit.utrNo,
		});
	};

	addVendorSettlements = async () => {
		// console.log("===== in addVendorSettlements =====");
		// console.log("this.state in add button----->",this.state)

		let hasError = false;

		// if(!this.state.settlementId){ hasError = true; this.setState({ settlementIdError: true}); }

		// check vendor id & compare the entered vendor id with vendor id in business collection ----- no validations required
		// if(!this.state.vendorId){
		// 	hasError = true;
		// 	this.setState({ vendorIdError: true});
		// }
		// if(this.state.vendorId){
		// 	hasError = this.props.businessData.cashfreeVendorId !== this.state.vendorId ? true : false;
		// 	this.setState({ vendorIdCompareError: this.props.businessData.cashfreeVendorId !== this.state.vendorId ? true : false})
		// }

		if(this.state.totalTransactionAmount && !/^\+?[0-9]*(\.[0-9]*|\.[0-9]+)?$$/i.test(this.state.totalTransactionAmount)){
			hasError = true;
			this.setState({ totalTransactionAmountError: true});
		}
		if(this.state.serviceCharge && !/^\+?[0-9]*(\.[0-9]*|\.[0-9]+)?$$/i.test(this.state.serviceCharge)){
			hasError = true;
			this.setState({ serviceChargeError: true});
		}
		if(this.state.serviceTax && !/^\+?[0-9]*(\.[0-9]*|\.[0-9]+)?$$/i.test(this.state.serviceTax)){
			hasError = true;
			this.setState({ serviceTaxError: true});
		}
		if(this.state.settlementAmount && !/^\+?[0-9]*(\.[0-9]*|\.[0-9]+)?$$/i.test(this.state.settlementAmount)){
			hasError = true;
			this.setState({ settlementAmountError: true});
		}
		if(this.state.adjustedAmount && !/^\+?[0-9]*(\.[0-9]*|\.[0-9]+)?$$/i.test(this.state.adjustedAmount)){
			hasError = true;
			this.setState({ adjustedAmountError: true});
		}

		if(!this.state.netSettled){ hasError = true; this.setState({ netSettledError: true}); }
		if(this.state.netSettled && !/^\+?[0-9]*(\.[0-9]*|\.[0-9]+)?$$/i.test(this.state.netSettled)){
			hasError = true;
			this.setState({ netSettledValidationError: true});
		}

		// if(!this.state.transactionFrom){ 
		// 	hasError = true;
		// 	this.setState({ transactionFromError: true});
		// }
		// if(!this.state.transactionFromTime){ 
		// 	hasError = true;
		// 	this.setState({ transactionFromTimeError: true});
		// }
		

		// if(!this.state.transactionTo){ 
		// 	hasError = true;
		// 	this.setState({ transactionToError: true});
		// }
		// if(!this.state.transactionToTime){ 
		// 	hasError = true;
		// 	this.setState({ transactionToTimeError: true});
		// }

		if(this.state.transactionFrom && this.state.transactionFromTime && this.state.transactionTo && this.state.transactionToTime){
			if(new Date(this.state.transactionTo + " " + this.state.transactionToTime) < new Date(this.state.transactionFrom + " " + this.state.transactionFromTime)){ hasError = true; this.setState({ toError: true }); }
		}

		// if(this.state.transactionFrom && this.state.transactionTo){
		// 	let from = new Date(this.state.transactionFrom).toISOString();
		// 	let to = new Date(this.state.transactionTo).toISOString();
		// 	if(to<from){
		// 		hasError = true;
		// 		this.setState({ toError: true });
		// 	}
		// }

		if(!this.state.settledOn){ hasError = true; this.setState({ settledOnError: true}); }
		if(!this.state.settledOnTime){ hasError = true; this.setState({ settledOnTimeError: true}); }

		// if(this.state.transactionFrom && this.state.transactionTo && this.state.settledOn){
		// 	let from = new Date(this.state.transactionFrom).toISOString();
		// 	let to = new Date(this.state.transactionTo).toISOString();
		// 	let settle = new Date(this.state.settledOn).toISOString();
		// 	if(settle<from || settle<to){
		// 		hasError = true;
		// 		this.setState({ settleError: true });
		// 	}
		// }

		if(this.state.transactionFrom && this.state.transactionFromTime && this.state.transactionTo && this.state.transactionToTime && this.state.settledOn && this.state.settledOnTime){
			let from = new Date(this.state.transactionFrom + ' ' + this.state.transactionFromTime).toISOString();
			let to = new Date(this.state.transactionTo + ' ' + this.state.transactionToTime).toISOString();
			let settle = new Date(this.state.settledOn + ' ' + this.state.settledOnTime).toISOString();
            
			if(settle<from || settle<to){ hasError = true; this.setState({ settleError: true }); }
		}
		
        if(this.state.settledOn>moment(new Date()).format('YYYY-MM-DD')){ hasError = true; this.setState({ settledOnCurrentError: true})}
		if(!this.state.utrNo){ hasError = true; this.setState({ utrNoError: true}); }

		if(!hasError){
			let transactionFromDateTime = this.state.transactionFrom + ' ' + this.state.transactionFromTime;
			let transactionToDateTime = this.state.transactionTo + ' ' + this.state.transactionToTime;
			let settledDateTime = this.state.settledOn + ' ' + this.state.settledOnTime;
			
			let payload = {
				businessName: this.props.businessData.businessName,
				businessId: this.props.businessData.businessId,
				businessNumber: this.props.businessData._id,
				// settlementId: this.state.settlementId,
				// cashfreeVendorId: this.state.vendorId,
				totalTransactionAmount: this.state.totalTransactionAmount ? parseFloat(this.state.totalTransactionAmount) : 0,
				serviceCharge: this.state.serviceCharge ? parseFloat(this.state.serviceCharge) : 0,
				serviceTax: this.state.serviceTax ? parseFloat(this.state.serviceTax) : 0,
				settlementAmount: this.state.settlementAmount ? parseFloat(this.state.settlementAmount) : 0,
				adjustedAmount: this.state.adjustedAmount ? parseFloat(this.state.adjustedAmount) : 0,
				netSettledAmount: this.state.netSettled ? parseFloat(this.state.netSettled) : 0,
				// transactionFrom: new Date(this.state.transactionFrom).toISOString(),
				// transactionTo: new Date(this.state.transactionTo).toISOString(),
				// settledOn: new Date(this.state.settledOn).toISOString(),
				// transactionFrom: new Date(transactionFromDateTime).toISOString(),
				// transactionFromTime: this.state.transactionFromTime,
				// transactionTo: new Date(transactionToDateTime).toISOString(),
				// transactionToTime: this.state.transactionToTime,
				settledOn: new Date(settledDateTime).toISOString(),
				// settledOnTime: this.state.settledOnTime,
				utrNo: this.state.utrNo,
				status: this.state.status,
				source: "manual"
			};

			if(this.state.transactionFrom && this.state.transactionFromTime){
				payload.transactionFrom = new Date(transactionFromDateTime).toISOString();
			}
			if(this.state.transactionTo && this.state.transactionToTime){
				payload.transactionTo = new Date(transactionToDateTime).toISOString();
			}
			if(this.state.transactionId){
				payload.transactionId = this.state.transactionId;
			}

			if(this.props.edit){
				console.log("====== edit the settlmentRecords=====");
				this.updateData(payload);
				this.setState({ saveBtn: true });
			}else{
				console.log("====== add the settlmentRecords=====");
				console.log("===== payload before sending======",payload);
				this.props.addVendorSettlements(payload);
				this.setState({ saveBtn: true });
			}
		}
	};

	updateData = (editData) => {
		let transactionFromDateTime = this.state.transactionFrom + ' ' + this.state.transactionFromTime;
		let transactionToDateTime = this.state.transactionTo + ' ' + this.state.transactionToTime;
		let settledDateTime = this.state.settledOn + ' ' + this.state.settledOnTime;
		console.log("===== inside update data =====", transactionFromDateTime, transactionToDateTime);
		
		let payload = {
			businessNumber: this.props.selectedRowEdit.businessNumber,
			settlementNumber: this.props.selectedRowEdit._id,
			data: {
				// transactionFrom: new Date(this.state.transactionFrom).toISOString(),
				// transactionTo: new Date(this.state.transactionTo).toISOString(),
				// settledOn: new Date(this.state.settledOn).toISOString()

				transactionFrom: "",
				transactionTo: "",
				settledOn: new Date(settledDateTime).toISOString(),
				// transactionFromTime: this.state.transactionFromTime,
				// transactionToTime: this.state.transactionToTime,
				// settledOnTime: this.state.settledOnTime,
			}
		};

		if(this.state.transactionFrom){
			payload.data.transactionFrom = new Date(transactionFromDateTime).toISOString()
		}
		if(this.state.transactionTo){
			payload.data.transactionTo = new Date(transactionToDateTime).toISOString()
		}

		// if(editData.settlementId !== this.props.selectedRowEdit.settlementId){
		// 	payload.data.settlementId = editData.settlementId;
		// }
		if(editData.transactionId !== this.props.selectedRowEdit.transactionId){
			payload.data.transactionId = editData.transactionId;
		}
		if(editData.totalTransactionAmount !== this.props.selectedRowEdit.totalTransactionAmount){
			payload.data.totalTransactionAmount = editData.totalTransactionAmount;
		}
		if(editData.serviceCharge !== this.props.selectedRowEdit.serviceCharge){
			payload.data.serviceCharge = editData.serviceCharge;
		}
		if(editData.serviceTax !== this.props.selectedRowEdit.serviceTax){
			payload.data.serviceTax = editData.serviceTax;
			
		}
		if(editData.settlementAmount !== this.props.selectedRowEdit.settlementAmount){
			payload.data.settlementAmount = editData.settlementAmount;
		}
		if(editData.adjustedAmount !== this.props.selectedRowEdit.adjustedAmount){
			payload.data.adjustedAmount = editData.adjustedAmount;
		}
		if(editData.netSettledAmount !== this.props.selectedRowEdit.netSettledAmount){
			payload.data.netSettledAmount = editData.netSettledAmount;
		}
		if(editData.utrNo !== this.props.selectedRowEdit.utrNo){
			payload.data.utrNo = editData.utrNo;
		}
		

		console.log("====== payload",payload);

		this.props.updateVendorSettlements(payload);
	}

	close = () => {
		this.props.onClose();
	};

	handleChange = (event) => {
		// if (event.target.name === "settlementId") { this.setState({ [event.target.name]: event.target.value, settlementIdError: false }); }
		if (event.target.name === "transactionId") { this.setState({ [event.target.name]: event.target.value, transactionIdError: false }); }
		// if (event.target.name === "vendorId") { this.setState({ [event.target.name]: event.target.value, vendorIdError: false, vendorIdCompareError: false }); }
		if (event.target.name === "totalTransactionAmount") { this.setState({ [event.target.name]: event.target.value, totalTransactionAmountError: false }); }
		if (event.target.name === "serviceCharge") { this.setState({ [event.target.name]: event.target.value, serviceChargeError: false }); }
		if (event.target.name === "serviceTax") { this.setState({ [event.target.name]: event.target.value, serviceTaxError: false }); }
		if (event.target.name === "settlementAmount") { this.setState({ [event.target.name]: event.target.value, settlementAmountError: false }); }
		if (event.target.name === "adjustedAmount") { this.setState({ [event.target.name]: event.target.value, adjustedAmountError: false }); }
		if (event.target.name === "netSettled") { this.setState({ [event.target.name]: event.target.value, netSettledError: false, netSettledValidationError: false }); }
		if (event.target.name === "transactionFrom") { this.setState({ [event.target.name]: event.target.value, transactionFromError: false }); }
		if (event.target.name === "transactionFromTime") { this.setState({ [event.target.name]: event.target.value, transactionFromTimeError: false }); }
		if (event.target.name === "transactionTo") { this.setState({ [event.target.name]: event.target.value, transactionToError: false, toError: false }); }
		if (event.target.name === "transactionToTime") { this.setState({ [event.target.name]: event.target.value, transactionToTimeError: false, toError: false }); }
		if (event.target.name === "settledOn") {
			 this.setState({ [event.target.name]: event.target.value, settledOnError: false, settleError: false ,settledOnCurrentError: false}); }
		if (event.target.name === "settledOnTime") { this.setState({ [event.target.name]: event.target.value, settledOnTimeError: false, settleError: false }); }
		if (event.target.name === "utrNo") { this.setState({ [event.target.name]: event.target.value, utrNoError: false }); }
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { classes } = this.props;

		// console.log("===== props in add form=====",this.props);
		// console.log("===== state in add form=====",this.state);
		
        return (
			<Modal
				className={classes.modal}
				open={this.props.open}
				closeAfterTransition >
				<Container className={classes.mainContainer}>
					<Grid container className={classes.header} spacing={1} >
						<Grid item xs={8}>
							<WhiteTextTypography variant="subtitle1" color="inherit" gutterBottom >
								{!this.props.edit ? "Add " : "Edit "} Vendor Split Settlement
							</WhiteTextTypography>
						</Grid>
						<Grid item xs={4}>
							<WhiteTextTypography variant="subtitle1" color="inherit" gutterBottom >
								<CloseIcon className={classes.headGridTwo} onClick={() => { this.close() }} />
							</WhiteTextTypography>
						</Grid>
					</Grid>

                    <Container className={classes.modalContainer}>
                        <Grid container className={classes.grid} spacing={1} alignItems="center" >
							<Grid item xs={12} sm={6} ><Typography variant="subtitle2" gutterBottom>Business ID</Typography></Grid>
							{!this.props.edit ? null : <Grid item xs={12} sm={6} ><Typography variant="subtitle2" gutterBottom>Settlement ID</Typography></Grid>}
							{!this.props.edit ? <Grid item xs={12} sm={6} ></Grid> : null}

                            {/* <Grid item xs={12} sm={6} >
                                <TextField fullWidth margin="dense" name="settlementId" label="Settlement ID" variant="outlined" value={this.state.settlementId} onChange={this.handleChange} error={this.state.settlementIdError ? true : false} />
							</Grid> */}
                            <Grid item xs={12} sm={6} >
								<Typography variant="subtitle1" gutterBottom>
                                	{this.state.vendorId ? this.state.vendorId : "--"}
                            	</Typography>
                                {/* <TextField fullWidth margin="dense" name="vendorId" label="Vendor ID" variant="outlined" value={this.state.vendorId} disabled
								// onChange={this.handleChange} 
								// error={this.state.vendorIdError ? true : (this.state.vendorIdCompareError ? true : false)} 
								/> */}
							</Grid>
							{!this.props.edit ? null : <Grid item xs={12} sm={6} > <Typography variant="subtitle1" gutterBottom>
                                	{this.state.settlementId ? this.state.settlementId : "--"}
                            	</Typography> </Grid>
							}
							{!this.props.edit ? <Grid item xs={12} sm={6} ></Grid> : null}
							

							<Grid item xs={6}></Grid>
                            {/* <Grid item xs={6}><FormHelperText style={{ color: "red", marginLeft:'10px',marginTop:'0px', marginBottom:'10px' }}> {this.state.vendorIdError?"Required":(this.state.vendorIdCompareError?"Vendor ID mismatch":null)} </FormHelperText></Grid> */}
                            <Grid item xs={6}></Grid>

							<Grid item xs={12} sm={6} ><Typography variant="subtitle2" gutterBottom>Transaction ID</Typography></Grid>
							<Grid item xs={12} sm={6} ></Grid>

							<Grid item xs={12} sm={6} >
                                <TextField fullWidth margin="dense" name="transactionId" label="Transaction ID" variant="outlined" value={this.state.transactionId} onChange={this.handleChange} />
							</Grid>
							<Grid item xs={12} sm={6} ></Grid>

							<Grid item xs={12} sm={6}></Grid>
							<Grid item xs={12} sm={6}></Grid>

                            <Grid item xs={12}><Divider color="textSecondary" style={{ marginBottom: "5px" }}display="block"/></Grid>

                            <Grid item xs={12} sm={6} >
								<Typography variant="subtitle2" gutterBottom>Total Transaction Amount</Typography>
							</Grid>
                            <Grid item xs={12} sm={6} ><Typography variant="subtitle2" gutterBottom>Service Charge</Typography></Grid>
                            <Grid item xs={12} sm={6} >
                                <TextField fullWidth margin="dense" name="totalTransactionAmount" label="Total Transaction Amount" variant="outlined" value={this.state.totalTransactionAmount} onChange={this.handleChange} error={this.state.totalTransactionAmountError ? true : false} />
							</Grid>
							
                            <Grid item xs={12} sm={6} >
                                <TextField fullWidth margin="dense" name="serviceCharge" label="Service Charge" variant="outlined" value={this.state.serviceCharge} onChange={this.handleChange} error={this.state.serviceChargeError ? true : false} />
							</Grid>

							<Grid item xs={6}><FormHelperText style={{ color: "red", marginLeft:'10px',marginTop:'0px', marginBottom:'10px'}}> {this.state.totalTransactionAmountError?"Should be a number":null} </FormHelperText></Grid>
                            <Grid item xs={6}><FormHelperText style={{ color: "red", marginLeft:'10px',marginTop:'0px', marginBottom:'10px'}}> {this.state.serviceChargeError?"Should be a number":null} </FormHelperText></Grid>

                            <Grid item xs={12}></Grid>

                            <Grid item xs={12} sm={6} ><Typography variant="subtitle2" gutterBottom>Service Tax</Typography></Grid>
                            <Grid item xs={12} sm={6} ><Typography variant="subtitle2" gutterBottom>Settlement Amount</Typography></Grid>
                            <Grid item xs={12} sm={6} >
                                <TextField fullWidth margin="dense" name="serviceTax" label="Service Tax" variant="outlined" value={this.state.serviceTax} onChange={this.handleChange} error={this.state.serviceTaxError ? true : false} />
							</Grid>
                            <Grid item xs={12} sm={6} >
                                <TextField fullWidth margin="dense" name="settlementAmount" label="Settlement Amount" variant="outlined" value={this.state.settlementAmount} onChange={this.handleChange} error={this.state.settlementAmountError ? true : false} />
							</Grid>

							<Grid item xs={6}><FormHelperText style={{ color: "red", marginLeft:'10px',marginTop:'0px', marginBottom:'10px'}}> {this.state.serviceTaxError?"Should be a number":null} </FormHelperText></Grid>
                            <Grid item xs={6}><FormHelperText style={{ color: "red", marginLeft:'10px',marginTop:'0px', marginBottom:'10px'}}> {this.state.settlementAmountError?"Should be a number":null} </FormHelperText></Grid>

                            <Grid item xs={12}></Grid>

                            <Grid item xs={12} sm={6} ><Typography variant="subtitle2" gutterBottom>Adjusted Amount</Typography></Grid>
                            <Grid item xs={12} sm={6} ><Typography variant="subtitle2" gutterBottom>Net Settled Amount *</Typography></Grid>
                            <Grid item xs={12} sm={6} >
                                <TextField fullWidth margin="dense" name="adjustedAmount" label="Adjusted Amount" variant="outlined" value={this.state.adjustedAmount} onChange={this.handleChange} error={this.state.adjustedAmountError ? true : false} />
							</Grid>
                            <Grid item xs={12} sm={6} >
                                <TextField fullWidth margin="dense" name="netSettled" label="Net Settled" variant="outlined" value={this.state.netSettled} onChange={this.handleChange} error={this.state.netSettledError ? true : (this.state.netSettledValidationError ? true : false)} />
							</Grid>

							<Grid item xs={6}><FormHelperText style={{ color: "red", marginLeft:'10px',marginTop:'0px', marginBottom:'10px'}}> {this.state.adjustedAmountError?"Should be a number":null} </FormHelperText></Grid>
                            <Grid item xs={6}><FormHelperText style={{ color: "red", marginLeft:'10px',marginTop:'0px', marginBottom:'10px'}}> {this.state.netSettledError?"Required":(this.state.netSettledValidationError ? "Should be a number" : null)} </FormHelperText></Grid>

                            <Grid item xs={12}></Grid>
                            <Grid item xs={12}><Divider color="textSecondary" style={{ marginBottom: "5px" }} display="block"/></Grid>

                            <Grid item xs={12} sm={6} ><Typography variant="subtitle2" gutterBottom>Transaction From Date</Typography></Grid>
                            <Grid item xs={12} sm={6} ><Typography variant="subtitle2" gutterBottom>Transaction From Time</Typography></Grid>
							
							<Grid item xs={12} sm={6} >
                                <TextField fullWidth margin="dense" name="transactionFrom" label="Select from date" variant="outlined" value={this.state.transactionFrom} InputLabelProps={{shrink: true}} type="date"
                                onChange={this.handleChange} error={this.state.transactionFromError ? true : false}
                                />
							</Grid>
							<Grid item xs={12} sm={6} >
                                <TextField fullWidth margin="dense" name="transactionFromTime" label="Select from time" variant="outlined" value={this.state.transactionFromTime} InputLabelProps={{shrink: true}} type="time"
                                onChange={this.handleChange} error={this.state.transactionFromTimeError ? true : false}
                                />
							</Grid>

							<Grid item xs={6}><FormHelperText style={{ color: "red", marginLeft:'10px',marginTop:'0px', marginBottom:'10px'}}> {this.state.transactionFromError?"Required":null} </FormHelperText></Grid>
							<Grid item xs={6}><FormHelperText style={{ color: "red", marginLeft:'10px',marginTop:'0px', marginBottom:'10px'}}> {this.state.transactionFromTimeError?"Required":null} </FormHelperText></Grid>

							<Grid item xs={12} sm={6} ><Typography variant="subtitle2" gutterBottom>Transaction To Date</Typography></Grid>
							<Grid item xs={12} sm={6} ><Typography variant="subtitle2" gutterBottom>Transaction To Time</Typography></Grid>

							<Grid item xs={12} sm={6} >
                                <TextField fullWidth margin="dense" name="transactionTo" label="Select to date" variant="outlined" value={this.state.transactionTo} InputLabelProps={{shrink: true}} type="date"
                                onChange={this.handleChange} error={this.state.transactionToError ? true : (this.state.toError ? true : false)}
                                />
							</Grid>
							<Grid item xs={12} sm={6} >
                                <TextField fullWidth margin="dense" name="transactionToTime" label="Select to time" variant="outlined" value={this.state.transactionToTime} InputLabelProps={{shrink: true}} type="time"
                                onChange={this.handleChange} error={this.state.transactionToTimeError ? true : (this.state.toError ? true : false)}
                                />
							</Grid>

							<Grid item xs={6}><FormHelperText style={{ color: "red", marginLeft:'10px',marginTop:'0px', marginBottom:'10px' }}> {this.state.transactionToError?"Required":(this.state.toError?"Transaction to date cannot be less than from date" : null)} </FormHelperText></Grid>
							<Grid item xs={6}><FormHelperText style={{ color: "red", marginLeft:'10px',marginTop:'0px', marginBottom:'10px' }}> {this.state.transactionToTimeError?"Required":(this.state.toError?"Transaction to time cannot be less than from time" : null)} </FormHelperText></Grid>
                            
                            <Grid item xs={12} sm={6} ><Typography variant="subtitle2" gutterBottom>Setttled On Date *</Typography></Grid>
                            <Grid item xs={12} sm={6} ><Typography variant="subtitle2" gutterBottom>Setttled On Time *</Typography></Grid>
                            
                            <Grid item xs={12} sm={6} >
                                <TextField fullWidth margin="dense" name="settledOn" label="Select settled on date" variant="outlined" value={this.state.settledOn} InputLabelProps={{shrink: true}} type="date" onChange={this.handleChange} error={(this.state.settledOnError ||this.state.settledOnCurrentError) ? true : (this.state.settleError ? true : false)} />
							</Grid>
                            <Grid item xs={12} sm={6} >
                                <TextField fullWidth margin="dense" name="settledOnTime" label="Select settled on time" variant="outlined" value={this.state.settledOnTime} InputLabelProps={{shrink: true}} type="time" onChange={this.handleChange} error={this.state.settledOnTimeError ? true : (this.state.settleError ? true : false)} />
							</Grid>

                            <Grid item xs={6}><FormHelperText style={{ color: "red", marginLeft:'10px',marginTop:'0px', marginBottom:'10px' }}> {this.state.settledOnError?"Required":this.state.settledOnCurrentError ?"Settled on date should not be greater than Current Date": (this.state.settleError?"Settled on date should be greater than transaction from and to dates":null)} </FormHelperText></Grid>
                            <Grid item xs={6}><FormHelperText style={{ color: "red", marginLeft:'10px',marginTop:'0px', marginBottom:'10px' }}> {this.state.settledOnTimeError?"Required":(this.state.settleError?"Settled on time should be greater than transaction from and to time":null)} </FormHelperText></Grid>

                            <Grid item xs={12}></Grid>
                            <Grid item xs={12}><Divider color="textSecondary" style={{ marginBottom: "5px" }} display="block"/></Grid>

                            <Grid item xs={12} sm={6} ><Typography variant="subtitle2" gutterBottom>UTR No./Cash *</Typography></Grid>
                            {/* <Grid item xs={12} sm={6} ><Typography variant="subtitle2" gutterBottom>Status *</Typography></Grid> */}
                            <Grid item xs={12} sm={6} ></Grid>
                            <Grid item xs={12} sm={6} >
                                <TextField fullWidth margin="dense" name="utrNo" label="UTR No./Cash" variant="outlined" value={this.state.utrNo}onChange={this.handleChange} error={this.state.utrNoError ? true : false} />
							</Grid>
                            <Grid item xs={12} sm={6} >
                                {/* <TextField fullWidth margin="dense" name="status" label="Status" variant="outlined" value={this.state.status} disabled /> */}
							</Grid>

							<Grid item xs={6}><FormHelperText style={{ color: "red", marginLeft:'10px',marginTop:'0px', marginBottom:'10px'}}> {this.state.utrNoError?"Required":null} </FormHelperText></Grid>
                            <Grid item xs={6}></Grid>

                            <Grid item xs={12}></Grid>
                            <Grid item xs={12}><Divider color="textSecondary" style={{ marginBottom: "5px" }} display="block"/></Grid>

                            <Grid item xs={4}></Grid>
							<Grid item xs={4}>
								<Button
									style={{ margin: "5px" }}
									fullWidth
									variant="contained"
									onClick={this.close}
								>
									Cancel
								</Button>
							</Grid>
							<Grid item xs={4}>
								<Button
									fullWidth
									style={{ margin: "5px" }}
									variant="contained"
									color="primary"
									onClick={this.addVendorSettlements}
									disabled={this.state.saveBtn ? true : false}
								>
									SAVE
								</Button>
							</Grid>
                        </Grid>
                    </Container>
				</Container>
			</Modal >
		);
    }
}
const mapStateToProps = (state) => {
	return {
		dashboard: state.dashboard,
		business: state.business,
		user: state.auth.user,
		categories: state.categories,
		env: state.auth.env
	};
};
export default connect(mapStateToProps, {})(withStyles(styles)(SettlementAddForm));