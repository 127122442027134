import React, { Component } from 'react';
import Grid from "@material-ui/core/Grid";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  imageOutermost: {
    width: '100%',
    height: '100%',
    padding: '0px 0px',

  },
  gridElement: {
    margin: '10px 5px'
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  icon: {
    color: 'white',
  },
  gridList: {
    width: '100%',
    height:'100%'
  },


});

class UploadSplashScreen extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteOpen: false,
      selectedPhoto: null
    };
    this.inputReference = React.createRef();
  }

  fileUploadAction = () => this.inputReference.current.click();

  onFileChange = async event => {
    let files = event.target.files;
    console.log("event.target.value in upload splash screen==>",event.target.value);
    // if(files && files[0]){this.props.uploadSplashScreen(files, this.props.photoType);}
    if (files && files[0]) {
      this.props.uploadSplashScreen(files, this.props.photoType);

      // Reset the file input value
      event.target.value = null;
  }
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid>
        <Grid className={classes.imageOutermost}>
          <Grid>
            <Button variant="contained" htmlFor="myInput2" onClick={this.fileUploadAction} color="primary" component="span" >
              {this.props.uploadButtonName}
            </Button>
            <input
              ref={this.inputReference}
              id="myInput2"
              name="UploadSplashScreenInput"
              style={{ display: 'none' }}
              type={"file"}
              onChange={this.onFileChange}
              accept="image/x-png,image/jpeg,image/gif,image/jpg,image/png"
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default (withStyles(styles)(UploadSplashScreen));
